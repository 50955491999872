<template>
  <section id="infosection" class="hero-section" v-if="infosection">
    <div class="container hero-container pt-5 pb-5">
      <div class="row">
        <div class="col-md-6 place-on-top">
          <h2>{{ infosection.title }}</h2>
          <div class="h-100 pt-3" v-html="infosection.description"></div>
        </div>
        <div class="col-md-6">
          <div class="ratio ratio-16x9">
            <img
              class="image ratio-16x9"
              :src="getImageUrl(infosection.image.path)"
              width="100%"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";

export default {
  name: "InfoSection",
  components: {},
  data() {
    return {
      infosection: null,
    };
  },
  created() {
    this.fetchAboutSection();
  },
  methods: {
    getImageUrl(uri) {
      return process.env.VUE_APP_UPLOADURL + uri;
    },
    fetchAboutSection() {
      let cockpiturl = process.env.VUE_APP_COCKPITURL;
      let token = process.env.VUE_APP_TOKEN;
      axios
        .get(cockpiturl + "/content/item/infosection", {
          headers: {
            "api-key": token,
          },
        })
        .then((response) => {
          // console.log(response.data);
          this.infosection = response.data;
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>

<style scoped>
.hero-section {
  background-color: #fff;
  color: #202c32;
}
.hero-container {
  padding: 1em;
}
.place-on-top {
  z-index: 1000;
}
.image {
  border-radius: 10px;
}
</style>
