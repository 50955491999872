<template>
  <section class="footer-section p-5 text-beige" v-if="footer">
    <div class="container">
      <div class="row">
        <div class="col-md-3">
          <a class="navbar-brand" href="https://quantes.nl" target="_blank">
            <img
              class="logo pb-3"
              src="../assets/logo.svg"
              alt="Bootstrap"
              width="141"
            />
          </a>
          <div v-html="footer.column_1"></div>
        </div>
        <div class="col-md-3">
          <div v-html="footer.column_2"></div>
        </div>
        <div class="col-md-3">
          <div v-html="footer.column_3"></div>
        </div>
        <div class="col-md-3">
          <div v-html="footer.column_4"></div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";

export default {
  name: "PlanetSection",
  components: {},
  computed: {
    getActiveYear() {
      return this.$store.getters.getActiveYear;
    },
  },
  data() {
    return {
      footer: null,
    };
  },
  created() {
    this.fetchWorkflowSection();
  },
  methods: {
    setYear(year) {
      this.$store.commit("setActiveYear", year);
    },
    fetchWorkflowSection() {
      let cockpiturl = process.env.VUE_APP_COCKPITURL;
      let token = process.env.VUE_APP_TOKEN;
      axios
        .get(cockpiturl + "/content/item/footer", {
          headers: {
            "api-key": token,
          },
        })
        .then((response) => {
          // console.log(response.data);
          this.footer = response.data;
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>

<style lang="css">
.footer-section {
  background-color: #202c32;
}
.footer-container {
  padding: 2em;
}
.overlay {
  position: absolute;
  visibility: visible;
}
.nav-tabs .nav-link.active {
  background-color: #202c32 !important;
  border-bottom-color: #202c32;
}
.logo {
  filter: brightness(0) saturate(100%) invert(90%) sepia(10%) saturate(447%)
    hue-rotate(333deg) brightness(110%) contrast(88%);
}
.text-beige {
  color: #f0ede0;
}
a,
a:link,
a:hover,
a:visited {
  color: #f0ede0;
}
</style>
