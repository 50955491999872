<template>
  <div
    :id="'carousel' + bulletinboard._id"
    class="carousel slide"
    data-bs-ride="carousel"
  >
    <div class="carousel-inner">
      <div
        class="carousel-item"
        v-for="(item, index) in bulletinboard.image"
        v-bind:key="item"
        :class="{ active: index === 0 }"
      >
        <div class="image-container">
          <img
            :src="'https://db.winnenindelft.nl/storage/uploads/' + item.path"
            class="d-block w-100 ratio ratio-4x3"
          />
        </div>
      </div>
    </div>
    <button
      v-if="bulletinboard.image.length > 1"
      class="carousel-control-prev"
      type="button"
      :data-bs-target="'#carousel' + bulletinboard._id"
      data-bs-slide="prev"
    >
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button
      v-if="bulletinboard.image.length > 1"
      class="carousel-control-next"
      type="button"
      :data-bs-target="'#carousel' + bulletinboard._id"
      data-bs-slide="next"
    >
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>
</template>

<script>
import { Carousel } from "bootstrap";
import { nextTick } from "vue"; // Correctly import nextTick

export default {
  name: "CarouselComponent",
  props: {
    bulletinboard: Object,
  },
  mounted() {
    this.initCarousel();
  },
  watch: {
    "bulletinboard.image": function () {
      nextTick(() => {
        this.initCarousel();
      });
    },
  },
  methods: {
    initCarousel() {
      const carouselElement = document.querySelector(
        "#carousel" + this.bulletinboard._id
      );
      new Carousel(carouselElement, {
        interval: 3000,
        ride: "carousel",
      });
    },
  },
};
</script>

<style scoped>
.image-container {
  position: relative;
  width: 100%;
  padding-top: 75%; /* 4:3 aspect ratio */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.image-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.carousel {
  border-radius: 1em;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  overflow: hidden;
}
</style>
