<template>
  <section id="workflowsection" class="hero-section" v-if="workflowsection">
    <div class="container hero-container">
      <div class="row align-items-center">
        <div class="col-12 align-middle">
          <h2>{{ workflowsection.title }}</h2>
          <div class="h-100 pb-3" v-html="workflowsection.description"></div>
        </div>
      </div>
      <div class="row align-items-center">
        <div
          class="col-md-4 align-middle text-center"
          v-for="processpart in workflowsection.processpart"
          v-bind:key="processpart"
        >
          <div class="p-5">
            <img
              class="swing"
              :src="getImageUrl(processpart.image.path)"
              width="175"
            />
          </div>
          <div class="h-100 pb-3" v-html="processpart.description"></div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";

export default {
  name: "WorkflowSection",
  components: {},
  data() {
    return {
      workflowsection: null,
    };
  },
  created() {
    this.fetchWorkflowSection();
  },
  methods: {
    getImageUrl(uri) {
      return process.env.VUE_APP_UPLOADURL + uri;
    },
    fetchWorkflowSection() {
      let cockpiturl = process.env.VUE_APP_COCKPITURL;
      let token = process.env.VUE_APP_TOKEN;
      axios
        .get(cockpiturl + "/content/item/workflow", {
          headers: {
            "api-key": token,
          },
        })
        .then((response) => {
          // console.log(response.data);
          this.workflowsection = response.data;
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>

<style scoped>
.hero-section {
  background-color: #fff;
  color: #202c32;
}
.hero-container {
  padding: 1em;
}
</style>
