<template>
  <section id="bulletinsection" class="hero-section" v-if="bulletinboards">
    <div class="container hero-container pt-5 pb-5">
      <div class="row align-items-center">
        <div class="col-12 align-middle">
          <h2 class="pb-2">Prikbord</h2>
        </div>
      </div>

      <div class="row row-cols-1 row-cols-md-3 g-4">
        <div
          class="col"
          v-for="bulletinboard in bulletinboards"
          v-bind:key="bulletinboard"
        >
          <BulletinCard :bulletinboard="bulletinboard" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import BulletinCard from "@/components/BulletinCard.vue";

export default {
  name: "BulletinSection",
  components: { BulletinCard },
  data() {
    return {
      bulletinboards: null,
    };
  },
  created() {
    this.fetchWorkflowSection();
  },
  methods: {
    getImageUrl(uri) {
      return process.env.VUE_APP_UPLOADURL + uri;
    },
    fetchWorkflowSection() {
      let cockpiturl = process.env.VUE_APP_COCKPITURL;
      let token = process.env.VUE_APP_TOKEN;
      axios
        .get(cockpiturl + "/content/items/bulletinboard", {
          headers: {
            "api-key": token,
          },
        })
        .then((response) => {
          console.log(response.data);
          this.bulletinboards = response.data;
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>

<style scoped>
.hero-section {
  background-color: #f0ede0;
  color: #202c32;
}
.hero-container {
  padding: 2em;
}
.in-middle {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  height: 100vh;
}
</style>
