<template>
  <BackToTop />
  <HeroSection />
  <AboutSection />
  <WorkflowSection />
  <FoundersSection />
  <CTASection />
  <BulletinSection />
  <InfoSection />
  <PartnersSection />
  <ContactSection />
</template>

<script>
import BackToTop from "@/components/BackToTop.vue";
import HeroSection from "@/components/HeroSection.vue";
import AboutSection from "@/components/AboutSection.vue";
import WorkflowSection from "@/components/WorkflowSection.vue";
import FoundersSection from "@/components/FoundersSection.vue";
import CTASection from "@/components/CTASection.vue";
import BulletinSection from "@/components/BulletinSection.vue";
import InfoSection from "@/components/InfoSection.vue";
import PartnersSection from "@/components/PartnersSection.vue";
import ContactSection from "@/components/ContactSection.vue";

HeroSection;
export default {
  name: "HomeView",
  computed: {},
  created() {},
  components: {
    HeroSection,
    AboutSection,
    WorkflowSection,
    FoundersSection,
    CTASection,
    BulletinSection,
    PartnersSection,
    InfoSection,
    ContactSection,
    BackToTop,
  },
};
</script>

<style lang="css" scoped>
.skill-section {
  background-color: #faf5e6;
}
</style>
